import propTypes from 'prop-types';
import React from 'react';


const SellersTitle = ({ title, image, metaDescription }) => {
  return (
    <div className="bg-white my-12 pb-6 w-full justify-center items-center mx-auto">
        <div className="relative mx-auto shadow h-32 w-32 -my-12 border-white rounded-full overflow-hidden border-4">
          <img className="object-cover" src={image}></img>
        </div>
        <div className="mt-16 border-b border-gray-200">
          <h1 className="text-center font-extrabold">
            {title} 
          </h1>
          <p className="text-sm text-gray-700 text-center max-w-xl mx-auto">
            {metaDescription}
          </p>
        </div> 
    </div>
  );
};

SellersTitle.propTypes = {
  title: propTypes.string.isRequired,
  image: propTypes.string.isRequired,
  metaDescription: propTypes.string.isRequired,
};

export default SellersTitle;
